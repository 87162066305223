import React  from "react"
import Policy from "../components/Contract/Policy/Content.jsx"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
export default function PolicyPage() {
  

  

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/privacy-policy" />
      </Helmet>
      <Policy />
      
    </Layout>
  )
}
